import './App.css';
import React, {Component} from 'react';
import About from './components/about';
import Footer from './components/footer';
import Header from './components/header';
import Hero from './components/hero';
import Projects from './components/projects';
import WOW from 'wowjs';

class App extends Component {

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }

  render () {
    return (
      <>
        <Header></Header>
        <Hero></Hero>
        <About></About>
        <Projects></Projects>
        <Footer></Footer>
      </>
    );
  }
}

export default App;
