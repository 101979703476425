import React from "react";


const About = () => {
    return (
        <>
            <div className="container max-w-6xl mx-auto px-4 mt-20 lg:mt-[120px] about">
                <div className="grid grid-cols-7">
                    <div data-wow-duration="2s" className="wow fadeIn col-span-7 text-[#828282] font-700 text-[45px] leading-[70%] lg:text-[64px] lg:leading-[120%]">
                        About Me
                    </div>
                    <div data-wow-duration="3s" className="wow slideInLeft mt-5 col-span-6 text-white font-700 text-[20px] leading-[26px]">
                        I am a software engineer keen on creating note-worthy software solutions while staying updated 
                        with current development patterns. My objectives span across working individually 
                        and in teams to create and manage ideas that would shape the future of technology.
                    </div>
                    <div data-wow-duration="3s" className="wow slideInRight mt-5 col-span-6 text-white font-700 text-[20px] leading-[26px]">
                        Over the years, I have been consistenly involved in software development using tools such as ReactJs, NextJs, Laravel, Firebase, 
                        NodeJs, Java, and many others to build and deploy software solutions. I particularly manage development projects, build full stack web applications,
                        develop back end infrastructures and curate technical documentations for different systems.
                    </div>
                    {/* <div data-wow-duration="3s" className="wow slideInRight mt-5 col-span-6 text-white font-700 text-[20px] leading-[26px]">
                        For as long as I don’t know, 
                        I will remain in this line to work with like minds and raise like minds.
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default About