import React from "react";

const Header = () => {
    return (
        <div>
		<nav className="bg-black pt-3">
			<div className="max-w-6xl mx-auto px-2">
				<div className="flex justify-between">
					<div className="flex space-x-7">
						<div>
							<a href="" className="flex items-center py-4 px-2">
								<span className="font-semibold text-white text-lg text-[20px]">Lightsource</span>
							</a>
						</div>
					</div>
					<div className="hidden md:flex items-center space-x-2 ">
						<a href="mailto: israelakinakinsanya@gmail.com" target="_blank" className="py-[16px] px-[32px] font-medium text-black bg-white rounded-[24px] hover:bg-black hover:outline hover:outline-white hover:text-white transition duration-1000 w-[154px] text-center text-[16px]">Get In Touch</a>
					</div>
					<div className="md:hidden flex items-center">
						<a href="mailto: israelakinakinsanya@gmail.com" target="_blank" className="text-center outline-none rounded-[24px] p-1 bg-white mobile-menu-button w-[144px] hover:bg-black hover:outline hover:outline-white hover:text-white transition duration-1000">
							Get In Touch
						</a>
					</div>
				</div>
			</div>
		</nav>
	</div>
    )
}

export default Header