import React from "react";
import linkedin from '../../images/socials/linkedin.png'
import twitter from '../../images/socials/twitter.png'
import github from '../../images/socials/github.png'

const Footer = () => {
    return (
        <>
            <div className="container mt-10 lg:mt-20 mx-auto">
                <div className="grid grid-cols-3">
                    <div className="px-3 wow tada col-span-3 font-300 text-white leading-[48px] text-[32px] lg:leading-[67px] lg:text-[64px] mx-auto">
                        Have a project in mind?
                    </div>
                    <div data-wow-delay="1s" data-wow-duration="3s" className="wow fadeIn col-span-3 font-300 text-white leading-[48px] text-[32px] lg:leading-[67px] lg:text-[64px] mx-auto px-3">
                        Let me assist in making it a reality.
                    </div>
                    <div data-wow-delay="1.5s" data-wow-duration="3s" className="wow fadeIn col-span-3 font-300 text-white leading-[67px] text-[64px] mx-auto text-center">
                        <a href="mailto: israelakinakinsanya@gmail.com" target="_blank" className="py-[16px] px-[32px] font-medium text-black bg-white rounded-[24px] hover:bg-black hover:outline hover:outline-white hover:text-white transition duration-300 w-[144px] text-center text-[16px]">Contact Me</a>
                    </div>
                    <div className="col-span-3 font-300 text-white leading-[67px] text-[64px] mx-auto text-center mt-3 lg:mt-10">
                        <ul className="">
                                <li className="inline-block p-4 social"><a href="https://www.linkedin.com/in/israel-akin-akinsanya-773987186/"><img src={linkedin} alt="LinkedIn"/></a></li>
                                <li className="inline-block p-4 social"><a href="https://twitter.com/_light_source_"><img src={twitter} alt="Twitter"/></a></li>
                                <li className="inline-block p-4 social"><a href="https://github.com/Lightsource-Pris"><img src={github} alt="GitHub"/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer