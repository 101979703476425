import React from "react";
import demo from '../../images/projects/demo.png'
import cink from '../../images/projects/cink.png'
import lil from '../../images/projects/lilink.svg'
import jekinraa from '../../images/projects/jekinraa.png'
import prissolutions from '../../images/projects/prissolutions.png'
import jasline from '../../images/projects/jasline.png'
import budfox from '../../images/projects/budfox.png'
import agcouer from '../../images/projects/agcouer.png'
import pora from '../../images/projects/pora.svg'
import chgams from '../../images/projects/chgams.png'
import preloader from '../../images/projects/cachepreloader.png'

const Projects = () => {
    return (
        <>
            <div className="container max-w-6xl mx-auto px-4 mt-4 lg:mt-20 pt-10">
                <div className="grid grid-cols-6 gap-8">
                    <div data-wow-duration="2s" className="wow fadeIn col-span-6 text-[#828282] font-700 text-[45px] leading-[70%] lg:text-[64px] lg:leading-[120%]">
                        Featured Projects
                    </div>
                    <div data-wow-duration="2s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img src={chgams} alt="lillink"/></div>
                                <a href="https://chgams.com/" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                    <p>&lt;&#8725;&gt;</p>
                                    Go To CHGAMS
                                </a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leading-[38.4px]">CHGAMS</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                Charity Gift Aid Management System - A Saas application for managing donations and claiming gift aids.
                            </div>
                        </div>
                    </div>
                    <div data-wow-duration="2s" data-wow-delay="0.5s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img src={jekinraa} alt="Jekinraa"/></div>
                                <a href="https://jekinraa.com" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                <p>&lt;&#8725;&gt;</p>
                                    Go To Jekinraa</a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leadeing-[38.4px]">Jekinraa</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                A multifaceted platform developed to specifically solve food distribution system constructed with a number of the best technologies out there. 
                            </div>
                        </div>
                    </div>

                    <div data-wow-duration="2s" data-wow-delay="1s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img className="rounded-[8px]" src={pora} alt="Pora"/></div>
                                <a href="https://poraapp.com/" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                <p>&lt;&#8725;&gt;</p>
                                    Go To Pora</a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leadeing-[38.4px]">Pora</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                This app developed with Laravel, React Native stands unique among dating platforms. It's alogrithm releases the flexibily of also finding friends.
                            </div>
                        </div>
                    </div>

                    <div data-wow-duration="2s" data-wow-delay="1s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img src={cink} alt="Cinkandshare"/></div>
                                <a href="https://cinkandshare.netlify.app" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                <p>&lt;&#8725;&gt;</p>
                                    Cinkandshare</a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leadeing-[38.4px]">Cinkandshare</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                A system for managing shared subscription across paid services such as prime video, netflix, youtube and others.
                            </div>
                        </div>
                    </div>

                    <div data-wow-duration="2s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img className="rounded-[8px]" src={jasline} alt="Jasline"/></div>
                                <a href="https://jaslineentertainment.com" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                <p>&lt;&#8725;&gt;</p>
                                    Go To Jasline Entertainment</a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leadeing-[38.4px]">Jasline Entertainment</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                Backend Development of an Entertainment business website inclusive of a 
                                few service processing such as online purchase of tickets
                            </div>
                        </div>
                    </div>


                    <div data-wow-duration="2s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img src={preloader} alt="AGCOUER"/></div>
                                <a href="https://github.com/Lightsource-Pris/israel-wp-cache-preloader" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                <p>&lt;&#8725;&gt;</p>
                                    Go To Cachepreloader</a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leadeing-[38.4px]">Cache Preloader</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                This a wordpress plugin developed to increase load speed of post and page contents in wordpress management.
                            </div>
                        </div>
                    </div>

                    {/* <div data-wow-duration="2s" data-wow-delay="1s" className="wow fadeIn col-span-6 lg:col-span-3 text-white font-700 text-[20px] leading-[36px]">
                        <div className="grid grid-cols-1">
                            <div className="col-span-1 project relative group">
                                <div className="object-cover"><img className="rounded-[8px]" src={pora} alt="Pora"/></div>
                                <a href="https://poraapp.com/" target="_blank" className="rounded-[4px] absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-75 duration-500">
                                <p>&lt;&#8725;&gt;</p>
                                    Go To Pora</a>
                            </div>
                            <div className="col-span-1 text-white font-700 text-[24px] lg:text-[32px] mt-5 leadeing-[38.4px]">Pora</div>
                            <div className="col-span-1 text-[#E0E0E0] text-[18px] leading-[21.6px] mt-2">
                                This app developed with Laravel, React Native stands unique among dating platforms. It's alogrithm releases the flexibily of also finding friends.
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
        </>
    )
}

export default Projects